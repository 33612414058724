<template>

  <b-row>
    <b-col>
      <div class="iq-card p-4 mt-minus profile-view">
        
       <form id="msform" @submit.prevent="handleSubmit">
         <ul id="progressbar">
            <li :class="!isShow ? active : 'mr-3'" id="step-1" @click="changeSection()">
              Step 1
              <p class="m-0">Upload your photo</p>
            </li>
            <li id="step-2" :class="isShow ? active : ''" @click="changeSection()">
              Step 2
              <p class="m-0">Update your info</p>
            </li>
          </ul>
         <!-- <b-tabs content-class="mt-3"> -->
          <!-- <b-tab title="" v-if='!isShow' active> -->
            <fieldset v-if='!isShow'>
            <div class="text-center mt-5 sm-m-0">
              <h2 class="m-0">Welcome <span class="text-light-blue">{{user.name}} </span></h2>
              <p class="text-black">Upload your photo</p>
              <br>
              <img :src="image" width="200px" class="rounded-circle" alt=""> </div>
            <br>
            <br>
            <div class="form-group pb-5"> <span class="btn btn-primary btn-file px-4"> Upload
              <input type="file" v-on:change="onImageChange">
              </span> </div>
            <input type="button" name="next" class="next dark-btn font-size-16 mt-3 text-white float-right" value="Next Step" @click="changeSection()" />
          </fieldset>
          <!-- </b-tab> -->
          <!-- <b-tab title="" v-else > -->

            <fieldset v-else >
            <h2 class="m-0 text-center mt-5 sm-m-0">Update your info</h2>
            <p class="text-center">Share your information with our community</p>
           
                     <div class="  row align-items-center py-5 sm-p-0">
                    <div class="form-group col-sm-6">
                      <label for="name">Name:</label>
                      <input type="text" class="form-control" id="name" v-model="user.name">
                    </div>
                    <div class="form-group col-sm-6">
                      <label for="nickname">Prefer to be called:</label>
                      <input
                        type="text"
                        class="form-control"
                        id="prefer_to_be_called"
                        v-model="user.prefer_to_be_called"
                      />
                    </div>
                    <div class="form-group col-sm-6">
                      <label for="designation">Designation:</label>
                      <input
                        type="text"
                        class="form-control"
                        id="designation"
                        v-model="user.designation"
                      />
                    </div>
                    <div class="form-group col-sm-6">
                      <label for="platform">Platform:</label>
                       <input
                        type="text"
                        class="form-control"
                        id="platform"
                        v-model="user.platform"
                      />
                    </div>
                    <div class="form-group col-sm-6">
                      <label for="workingsince">Working Since:</label>
                      <input
                        type="text"
                        class="form-control"
                        id="working_since"
                        v-model="user.working_since"
                      />
                    </div>
                      <div class="form-group col-sm-6">
                      <label for="dob">Birthday:</label>
                      <input
                        type="date"
                        class="form-control"
                        id="birthday"
                        v-model="user.birthday"
                      />
                    </div>
                    <div class="form-group col-sm-6">
                      <label for="interests">Interests:</label>
                      <input
                        type="text"
                        class="form-control"
                        id="Interests"
                        v-model="user.Interests"
                      />
                    </div>
                    <div class="form-group col-sm-6">
                      <label for="spoken">Languages Known:</label>
                      <input
                        type="text"
                        class="form-control"
                        id="lang"
                        v-model="user.lang"
                      />
                    </div>
                     <div class="form-group col-sm-6">
                      <label for="personalemail">Email:</label>
                      <input type="email" class="form-control" id="email" v-model="user.email" disabled>
                    </div>
                    <div class="form-group col-sm-6">
                      <label for="personalemail">Personal Email:</label>
                      <input type="email" class="form-control" id="personalEmail" v-model="user.personalEmail">
                    </div>
                    <div class="form-group col-sm-6">
                      <label for="contact">Phone:</label>
                      <input
                        type="tel"
                        class="form-control"
                        id="phone"  
                        v-model="user.phone"
                      />
                    </div>
                    <div class="form-group col-sm-6">
                      <label>Country/City:</label>
                      <select id="country" name="country" v-model="user.country">
                        <option value="Afganistan">Afghanistan</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bonaire">Bonaire</option>
                        <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                        <option value="Botswana">Botswana</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                        <option value="Brunei">Brunei</option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Canary Islands">Canary Islands</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">Central African Republic</option>
                        <option value="Chad">Chad</option>
                        <option value="Channel Islands">Channel Islands</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">Christmas Island</option>
                        <option value="Cocos Island">Cocos Island</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote DIvoire">Cote DIvoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Curaco">Curacao</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">Dominican Republic</option>
                        <option value="East Timor">East Timor</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands">Falkland Islands</option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">French Polynesia</option>
                        <option value="French Southern Ter">French Southern Ter</option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Great Britain">Great Britain</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="India">India</option>
                        <option value="Iran">Iran</option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea North">Korea North</option>
                        <option value="Korea Sout">Korea South</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Laos">Laos</option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libya">Libya</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macau">Macau</option>
                        <option value="Macedonia">Macedonia</option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">Marshall Islands</option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Midway Islands">Midway Islands</option>
                        <option value="Moldova">Moldova</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Nambia">Nambia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherland Antilles">Netherland Antilles</option>
                        <option value="Netherlands">Netherlands (Holland, Europe)</option>
                        <option value="Nevis">Nevis</option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau Island">Palau Island</option>
                        <option value="Palestine">Palestine</option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">Papua New Guinea</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Phillipines">Philippines</option>
                        <option value="Pitcairn Island">Pitcairn Island</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Republic of Montenegro">Republic of Montenegro</option>
                        <option value="Republic of Serbia">Republic of Serbia</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russia">Russia</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="St Barthelemy">St Barthelemy</option>
                        <option value="St Eustatius">St Eustatius</option>
                        <option value="St Helena">St Helena</option>
                        <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                        <option value="St Lucia">St Lucia</option>
                        <option value="St Maarten">St Maarten</option>
                        <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                        <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                        <option value="Saipan">Saipan</option>
                        <option value="Samoa">Samoa</option>
                        <option value="Samoa American">Samoa American</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syria">Syria</option>
                        <option value="Tahiti">Tahiti</option>
                        <option value="Taiwan">Taiwan</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania">Tanzania</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Erimates">United Arab Emirates</option>
                        <option value="United States of America">United States of America</option>
                        <option value="Uraguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Vatican City State">Vatican City State</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Vietnam">Vietnam</option>
                        <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                        <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                        <option value="Wake Island">Wake Island</option>
                        <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zaire">Zaire</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                    </div>
                    <div class="form-group col-sm-6">
                      <label for="workinghours">Working Hours:</label>
                       <input
                        type="text"
                        class="form-control"
                        id="working_hours"
                        v-model='user.working_hours'
                      />
                    </div>
                   <div class="form-group col-sm-6">
                      <label for="timezone">Timezone:</label>
                      <select class="form-control" id="timezone" v-model='user.timezone'>
                        <option>IST</option>
                        <option>PST</option>
                        <option>GMT</option>
                        <option>UTC</option>
                        <option>EST</option>
                      </select>
                    </div>
                    <div class="form-group col-sm-6">
                      <label for="comment">Comment:</label>
                      <input
                        type="text"
                        class="form-control"
                        id="comment"
                        v-model='user.comment'
                      />
                    </div>
                                      
                    
                    <div class="form-group col-sm-6">
                      <label for="aboutme">About Me:</label>
                       <input
                        type="text"
                        class="form-control"
                        id="about_me"
                        v-model="user.about_me"
                      />
                    </div>
                  </div>
 
          

                    <div class="float-right">
              <input type="submit" name="previous" class="dark-btn  mr-2  font-size-16 sm-mt-1" value="Previous"   @click="changeSection()" />
            
              <button type="submit" class="dark-btn  mr-2  font-size-16 sm-mt-1">
                    Submit
                  </button>
              <!-- <a href="/" class="dark-btn font-size-16 mr-2  sm-mt-1">Dashboard</a> 
              <a href="/world-clock" class="dark-btn font-size-16 sm-mt-1">Clock</a>  -->
            </div>
          </fieldset>
          <!-- </b-tab> -->
        <!-- </b-tabs> -->
          <!-- <ul id="progressbar">
            <li class="active mr-3" id="step-1">Step 1
              <p class="m-0">Upload your photo</p>
            </li>
            <li id="step-2">Step 2
              <p class="m-0">Update your info</p>
            </li>
          </ul> -->
         
          
        </form>
      </div>
    </b-col>

  </b-row>
  
</template>
<script>
import { socialvue } from '../../../config/pluginInit'
import image from "../../../assets/images/user-icon.svg"
import axios from '../../../components/axios'
export default {
  name: 'SignProfileDetail',
  mounted () {
    socialvue.index()
     axios.defaults.headers["Authorization"] = 
    "Bearer " + localStorage.getItem("token");
   

    var userData =  JSON.parse(localStorage.getItem('user'));
    var metaData =  JSON.parse(userData.user_data);
    this.user.name = userData.name
    this.user.email = userData.email
    this.user.designation = metaData.designation
    // this.avatar = userData.avatar
  },
    data () {
    return {
      active:'active mr-3',
      image: image,
      isShow:false,
      user: {
        name: "",
        prefer_to_be_called: "",
        designation: "",
        platform: "",
        working_since: "",
        birthday: "",
        Interests: "",
        lang: "",
        email: "",
        phone: "",
        country: "",
        working_hours: "",
        timezone: "",
        comment: "",
        about_me: "",
        avatar: '',
         personalEmail:'',
        
      },
      currentPassword: '',
      timezone: [
        { value: 'IST', text: 'IST' },
        { value: 'PST', text: 'PST' },
        { value: 'GMT', text: 'GMT' },
        { value: 'UTC', text: 'UTC' },
        { value: 'EST', text: 'EST' }
      ],
      
        }
        
    },
    methods:{
      changeSection(){
        this.isShow = !this.isShow
      },
      onImageChange(event){
          this.user.avatar = event.target.files[0];
          this.image = URL.createObjectURL(this.user.avatar);
      },
      async handleSubmit(){
        // var d = new Date(this.user.dob)
        // var month = d.getMonth() + 1;
        // var day = d.getDate();
        // var year = d.getFullYear();
        
        // var nd = `${day}-${month}-${year}`;
        // this.user.dob = nd;
        const data = new FormData();

        data.append("avatar", this.user.avatar);
        data.append("name", this.user.name);
        if(this.user.prefer_to_be_called ) data.append("prefer_to_be_called", this.user.prefer_to_be_called);
        data.append("email", this.user.email);
        if(this.user.birthday) data.append("birthday", this.user.birthday);
        if(this.user.country) data.append("country", this.user.country);
        if(this.user.designation) data.append("designation", this.user.designation);
        if(this.user.platform) data.append("platform", this.user.platform);
        if(this.user.working_since) data.append("working_since", this.user.working_since);
        if(this.user.timezone) data.append("timezone", this.user.timezone);
        if(this.user.comment) data.append("comment", this.user.comment);
        if(this.user.about_me) data.append("about_me", this.user.about_me);
        if(this.user.working_hours) data.append("working_hours", this.user.working_hours);
        if(this.user.phone) data.append("phone", this.user.phone);
        if(this.user.Interests) data.append("Interests", this.user.Interests);
        if(this.user.lang) data.append("lang", this.user.lang);
        if(this.user.personalEmail) data.append("personalEmail", this.user.personalEmail);
    

        const config = {
          method: "post",
          url: "updateuser",
          data:data,
          headers: { 'content-type': 'multipart/form-data' }
        };
        await axios(config).then(res => {
          this.successMsg = 'Profile Updated';
          localStorage.setItem('user',JSON.stringify(res.data.user)) 
          this.$root.$emit('user', res.data.user);
          this.$router.push('/')
        }).catch(err => {
            console.log(err.response);
            this.error = err.response.data.message;
        })
     
      },
    }
   
}
</script>
<style>
.content-page{    margin-top: -30px;}
</style>